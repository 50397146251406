const templateVariantSettings = {
  header: "COMPRESSED",
  homePage: "HomeTemplateV3",
  about: null,
  contactUsPage: "ContactUsTemplateV3",
  dynamicPage: "DynamicTemplateV3",
  footer: null,
};

module.exports = templateVariantSettings;
