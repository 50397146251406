import React from "react";
import { Router } from "@reach/router";
import {
  AboutUsPage,
  aboutUsTemplatesVersionVariantsTypes, // Variantes de plantillas para el About Us
  colorsTypes, // Variantes de colores (GENERALMENTE SE UTILIZA ESTE)
  colorsVariantsTypes, // Variantes de colores (SOLO EN ALGUNOS CASOS)
  ContactUsPage,
  contactUsTemplatesVersionVariantsTypes, // Variantes de plantillas para el Contact Us
  DynamicPage,
  dynamicTemplatesVersionVariantsTypes,
  // fontsFamiliesTypes, // Variantes de plantillas para las paginas dinámicas
  // fontsFamiliesTypes, // Variantes de tipos de fuentes para los textos
  productVariantsTypes, // Variantes de plantillas para los producto
  productsVariantsTypes, // Variantes de plantillas para la lista de los productos
  HomePage,
  homeTemplatesVersionVariantTypes, // Variantes de plantillas para el Home
  textSizesVariantsTypes, // Variantes de tamaño de textos
  loginVariantTypes, // Variantes para el tipo de funcionamiento que posee el boton "Login"
} from "@homecu/blossom-websites-core-package";

import templateVariantSettings from "config";

// import { HOMEPAGE_DATA_MODEL } from "data/cms-models";

export default function Page() {
  // Personalizar boton de login en el menu de navegación (DESKTOP)
  const headerLoginButtonStyles = {
    variant: loginVariantTypes.NO_MODAL,
    grayscale: false, //  Habilitar o des-habilitar escala de grises
    // hoverGrayscaleFillBackgroundColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de fondo en su estado (hover) y con la escala de grises habilitada
    // hoverGrayscaleBorderColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de bordes en su estado (hover) y con la escala de grises habilitada
    // hoverGrayscaleFilledTextColor: colorsTypes.WHITE, // Color de fondo en su estado HOVER (solo aplica cunado la propiedad grayscale es TRUE y cuando la propiedad fill es FILLED)
    // grayscaleAndOFilledTextColor: colorsTypes.WHITE, // Color de textos (solo aplica cunado la propiedad grayscale es TRUE y cuando la propiedad fill es FILLED)
    background: colorsTypes.WHITE, // Color de fondo
    borderColor: colorsTypes.WHITE, // Color de bordes
    grayscaleTextColor: colorsTypes.PRIMARY_DARK,
    color: colorsTypes.PRIMARY_DARK, //  Cambiar el color de textos
    textColor: colorsTypes.PRIMARY_DARK,
    hoverBackground: colorsTypes.TRANSPARENT, // Color de fondo en su estado (HOVER)
    hoverBorderColor: colorsTypes.WHITE, // Color de bordes en su estado (HOVER)
    hoverColor: colorsTypes.WHITE, //  Cambiar el color de textos en su estado (HOVER)
    scrollBackground: colorsTypes.WHITE, // Color de fondo después de hacer SCROLL
    scrollBorderColor: colorsTypes.WHITE, // Color de bordes después de hacer SCROLL
    scrollColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de textos después de hacer SCROLL
    hoverScrollBackground: colorsTypes.TRANSPARENT, // Color de fondo después de hacer scroll en su estado (HOVER)
    hoverScrollBorderColor: colorsTypes.WHITE, // Color de bordes después de hacer scroll en su estado (HOVER)
    hoverScrollColor: colorsTypes.WHITE, // Color de textos después de hacer scroll en su estado (HOVER)
  };

  // Personalizar boton de login en el menu de navegación (MOBILE)
  const headerMobileLoginButtonStyles = {
    variant: loginVariantTypes.NO_MODAL,
    grayscale: true, //  Habilitar o des-habilitar escala de grises
    withoutBorderRadius: true, //  Habilitar o des-habilitar escala de grises
    isScrollChangeColorButton: true, //  Habilitar o des-habilitar es cambio de color al hacer SCROLL
    // hoverGrayscaleFillBackgroundColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de fondo en su estado (hover) y con la escala de grises habilitada
    // hoverGrayscaleBorderColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de bordes en su estado (hover) y con la escala de grises habilitada
    // hoverGrayscaleFilledTextColor: colorsTypes.WHITE, // Color de fondo en su estado HOVER (solo aplica cunado la propiedad grayscale es TRUE y cuando la propiedad fill es FILLED)
    // grayscaleAndOFilledTextColor: colorsTypes.WHITE, // Color de textos (solo aplica cunado la propiedad grayscale es TRUE y cuando la propiedad fill es FILLED)
    background: colorsTypes.WHITE, // Color de fondo
    borderColor: colorsTypes.WHITE, // Color de bordes
    color: colorsTypes.PRIMARY_DARK, //  Cambiar el color de textos
    hoverBackground: colorsTypes.WHITE, // Color de fondo en su estado (HOVER)
    hoverBorderColor: colorsTypes.WHITE, // Color de bordes en su estado (HOVER)
    hoverColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de textos en su estado (HOVER)
    scrollBackground: colorsTypes.WHITE, // Color de fondo después de hacer SCROLL
    scrollBorderColor: colorsTypes.WHITE, // Color de bordes después de hacer SCROLL
    scrollColor: colorsTypes.PRIMARY_DARK, //  Cambiar el color de textos después de hacer SCROLL
    hoverScrollBackground: colorsTypes.WHITE, // Color de fondo después de hacer scroll en su estado (HOVER)
    hoverScrollBorderColor: colorsTypes.WHITE, // Color de bordes después de hacer scroll en su estado (HOVER)
    hoverScrollColor: colorsTypes.PRIMARY_DARK, // Color de textos después de hacer scroll en su estado (HOVER)
  };

  // Personalizar boton de login en el Dropdown del menu de navegación
  const headerDropdownLoginButtonStyles = {
    hoverFilledBackgroundColor: colorsTypes.PRIMARY, //  Cambiar el color de fondo en su estado (hover)
    hoverFilledBorderColor: colorsTypes.PRIMARY, //  Cambiar el color de bordes en su estado (hover)
    hoverFilledTextColor: colorsTypes.WHITE, //  Cambiar el color de texto en su estado (hover)
  };

  //  Propiedades comunes entre los Pages/Paginas
  const commonProps = {
    config: templateVariantSettings, // Configuración inicial de la plantilla
    //  Configuración personalizada del Navigation Bar (Menu de Navegación)
    navigationMenuOptions: {
      // dropdownSeparationFromBottom: 10, // Cambiar la altura del sub-menu de navegación
    },
    // Personalizar Footer
    footerOptions: {
      customIconsAlt: "",
    },
    customIconsAlt: "",
    //  HEADER  –>  DESKTOP  –>  START
    headerDropdownLoginButtonStyles,
    headerLoginButtonStyles,
    headerMobileLoginButtonStyles,
    backgroundHeader: colorsTypes.TRANSPARENT,
    hoverBackgroundHeader: colorsTypes.TRANSPARENT,
    headerForceShow: true, //  Forzar la visualizacion del NavBar
    headerActiveLogoReplaceImageWithAfterBreakpoint: "", // Remplazar imagen de logo en el NavBar ACTIVO después de hacer SCROLL
    headerActiveLogoReplaceImageWith: "", // Remplazar imagen de logo en el NavBar ACTIVO
    headerLogoFilterEffect: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icono del menu de navegación (NavBar)
    headerLogoFilterEffectAfterScroll: ["none"], // Tipos de filtros para el icono del menu de navegación (NavBar) después de hacer SCROLL
    // headerLogoReplaceImageWith: "", // Remplazar imagen de logo en el NavBar
    // headerLogoReplaceImageWithAfterBreakpoint: "", // Remplazar imagen de logo en el NavBar después de hacer SCROLL
    headerBackgroundAfterBreakpoint: "rgba(37, 2, 4, 0.4)", //  Color de fondo del menu de navegación después de hacer SCROLL
    headerBackgroundAfterBreakpointHover: "rgba(37, 2, 4, 0.4)",
    backgroundAfterBreakpointHoverHeader: "rgba(37, 2, 4, 0.4)", // Color de fondo del menu de navegación después de hacer SCROLL en su estado Hover
    headerActiveBackground: colorsTypes.WHITE, // Color de fondo del menu de navegación en su estado Activo
    headerHoverActiveBackground: colorsTypes.WHITE, //  Color de fondo del menu de navegación en su estado Activo y Hover
    headerTransparentBackground: "rgba(37, 2, 4, 0.4)", //  Color de fondo del menu de navegación en su estado Transparente
    headerHoverTransparentBackground: "rgba(37, 2, 4, 0.4)", //  Color de fondo del menu de navegación en su estado Transparente & Hover
    // backgroundScreenHeader: false, // Habilitar o Deshabilitar background de tipo sombra al abrir el menu de navegación
    // headerNoFilterMenuIcon: true, // Forzar el no filtrar el icon del menu
    headerForceFilterMenuIcon: true, // Forzar el filtrado del icon del menu
    //  Props adicionales para el icono del menu en el navbar (DESKTOP)
    headerMenuIconStyles: {
      filterEffect: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono
      filterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono despues de hacer SCROLL
      activeFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono cuando el MENU esta ACTIVADO y despues de hacer SCROLL
      // mobileFilterEffect: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono en MOBILE
      // mobileFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono en MOBILE despues de hacer SCROLL
      // mobileActiveFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono en MOBILE cuando el MENU esta ACTIVO despues de hacer SCROLL
    },
    //  HEADER  –>  DESKTOP  –>  END

    //  HEADER  –>  MOBILE  –>  START
    // headerMobileLoginButtonIsScrollChangeColorButton: false, // Permite cambiar de colo al boton de LOGIN que esta en el NavBar después de hacer SCROLL
    headerMobileLogoReplaceImageWith: `url("/images/logo-black.svg")`, // Remplazar imagen de logo en el NavBar
    headerMobileLogoReplaceImageWithAfterBreakpoint: `url("/images/logo-black.svg")`, // Remplazar imagen de logo en el NavBar después de hacer SCROLL
    headerMobileActiveLogoReplaceImageWith: `url("/images/logo-black.svg")`, // Remplazar imagen de logo en el NavBar MOBILE ACTIVO
    headerMobileActiveLogoReplaceImageWithAfterBreakpoint: `url("/images/logo-black.svg")`, // Remplazar imagen de logo en el NavBar ACTIVO y después de hacer SCROLL
    headerMobileLogoFilterEffect: ["none"], // Tipos de filtros para el icono del menu de navegación (NavBar)
    headerMobileLogoFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icono del menu de navegación (NavBar) después de hacer SCROLL
    headerMobileActiveLogoFilterEffectAfterScroll: ["none"], // Tipos de filtros para el icono del menu de navegación (NavBar ACTIVO) después de hacer SCROLL
    headerMobileFilterEffect: [], // Tipos de filtros para el fondo del menu de navegación (NavBar)
    headerMobileFilterEffectAfterScroll: ["none"], // Tipos de filtros para el fondo del menu de navegación (NavBar) después de hacer SCROLL
    headerMobileMenuIconFilterEffect: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icon del menu de navegación en el (NavBar)
    headerMobileMenuIconFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icon del menu de navegación en el (NavBar) después de hacer SCROLL
    headerMobileMenuIconActiveFilterEffectAfterScroll: ["none"], // Tipos de filtros para el icon del menu de navegación en el (NavBar ACTIVO) después de hacer SCROLL
    headerMenuIconFilterEffect: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icon del menu de navegación en el (NavBar)
    headerMenuIconFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], // Tipos de filtros para el icon del menu de navegación en el (NavBar) después de hacer SCROLL
    headerMenuIconActiveFilterEffectAfterScroll: ["none"], // Tipos de filtros para el icon del menu de navegación en el (NavBar ACTIVO) después de hacer SCROLL
    headerMobileActiveLogoFilterEffect: ["none"], // Tipos de filtros para el icono del menu de navegación (NavBar ACTIVO)
    headerMobileBackgroundAfterBreakpoint: "rgba(37, 2, 4, 0.4)", // Color de fondo del menu de navegación después de hacer SCROLL
    headerMobileBackgroundAfterBreakpointHover: "rgba(37, 2, 4, 0.4)", // Color de fondo del menu de navegación después de hacer SCROLL
    headerMobileForceShow: true, //  Forzar la visualizacion del navbar
    headerMobileTransparent: false, //  NavBar Transparente
    headerMobileNoFilterLogo: true, // Forzar el no filtrar el logo del navbar
    headerMobileBackgroundMenu: colorsTypes.WHITE, //  Color de fondo del Dropdown (Sub-menus)
    headerMobileBackground: colorsTypes.WHITE, //  Color de fondo del navbar
    headerMobileActiveBackground: colorsTypes.WHITE, //  Color de fondo del navbar en su estado ACTIVO
    headerMobileHoverActiveBackground: colorsTypes.WHITE, //  Color de fondo del navbar en su estado ACTIVO & HOVER
    headerMobileTransparentBackground: colorsTypes.WHITE, //  Color de fondo del navbar (solo funciona cuando la propiedad TRANSPARENT es TRUE)
    headerMobileHoverFloatBackground: colorsTypes.WHITE, //  Color de fondo del navbar (solo funciona cuando la propiedad FLOAT es TRUE) en esu estado HOVER
    headerMobileLoginButtonScrollBackgroundAfterScroll: colorsTypes.WHITE, //  Color de fondo del  boton de logon en el NavBar después de hacer SCROLL
    // headerMobileLoginButtonScrollBackground: colorsTypes.WHITE, //  Color de fondo del boton de login en el navbar (solo funciona cuando se hizo SCROLL)
    headerMobileHoverTransparentBackground: "rgba(37, 2, 4, 0.4)", //  Color de fondo del boton de login en el navbar (solo funciona cuando la propiedad TRANSPARENT es TRUE) (solo funciona cuando se hizo SCROLL) en esu estado HOVER
    headerMobileLogoFilter: undefined, //  Filtra Logo del navbar
    headerMobileNoFilterMenuIcon: false, // Forzar el no filtrar el icono del menu en el navbar
    headerMobileForceFilterMenuIcon: false, // Filtrado forzoso del icono del menu en el navbar
    //  Props adicionales para el icono del menu en el navbar (MOBILE)
    headerMobileMenuIconStyles: {
      // filterEffect: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono
      // filterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono despues de hacer SCROLL
      // activeFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono cuando el MENU esta ACTIVADO y despues de hacer SCROLL
      mobileFilterEffect: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono en MOBILE
      mobileFilterEffectAfterScroll: ["invert()", "grayscale(1)", "saturate(0)"], //  Filtro personalizado para el icono en MOBILE despues de hacer SCROLL
      mobileActiveFilterEffectAfterScroll: ["none"], //  Filtro personalizado para el icono en MOBILE cuando el MENU esta ACTIVO despues de hacer SCROLL
    },
    //  HEADER  –>  MOBILE  –>  END
  };

  // Personalizar Tabs
  const tabsProps = {
    // Personalizar Tab
    tabOptions: {
      hiddenDivider: true, // Ocultar linea de division
      showAllBorders: true, //  Mostrar todos los bordes
      color: colorsVariantsTypes.LIGHT, // Color de textos
      fontSize: textSizesVariantsTypes.SMALL, //  Tamaño de textos
      activeBackgroundColor: colorsVariantsTypes.WHITE, //  Color de fondo en su estado activo
      oneLine: false, //  Textos en una solo linea
      backgroundColor: colorsVariantsTypes.VERY_ULTRA_LIGHT, // Color de fondo
      width: "100%", //  Ancho
      height: "100%", //  Alto
      padding: "15px 25px", // Espaciado interno del contendor
    },
  };

  // Personalizar Hero's
  const heroProps = {
    animate: true, // Habilita las animaciones en el hero
    // Personalizar estilos de los botones
    buttonStylesProps: {
      grayscale: true, // Habilitar o des-habilitar escala de grises
      hoverGrayscaleFillBackgroundColor: colorsTypes.TRANSPARENT, // Cambiar color de fondo en su estado (hover) y cuando esta habilitada la escala de grises (SOLO APLICA CUANDO EL VALOR FILL ES FILLED)
      hoverGrayscaleBorderColor: colorsTypes.WHITE, // Cambiar color de bordes en su estado (hover) y cuando esta habilitada la escala de grises
      hoverGrayscaleFilledTextColor: colorsTypes.PRIMARY_DARK, // Cambiar color de textos en su estado (hover) y cuando esta habilitada la escala de grises (SOLO APLICA CUANDO EL VALOR FILL ES FILLED)
      grayscaleAndOFilledTextColor: colorsTypes.PRIMARY_DARK,
      fontSize: textSizesVariantsTypes.VERY_SMALL, //  Tamaño de textos
      padding: "10px 30px", //  Espaciado interno
    },
  };

  //  Personalizar Productos
  const productsProps = {
    // subTitleFontFamily: fontsFamiliesTypes.PRIMARY, //  Tipo de fuente del sub títulos
    // titleFontFamily: fontsFamiliesTypes.SECONDARY, // Tipo de fuente del títulosgit
    getData: data => ({
      data: data.Products,
    }),
    variant: productsVariantsTypes.LIST,
    typeOfItems: productVariantsTypes.IMAGE_AND_TITLE,
  };

  return (
    <Router>
      <HomePage
        {...commonProps}
        path="/"
        variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V3}
        headerMobileLogoReplaceImageWith={`url("/images/logo-white.svg")`} // Remplazar imagen de logo en el NavBar
        headerForceShow={false} //  Forzar la visualizacion del NavBar
        headerMobileForceShow={false} //  Forzar la visualizacion del NavBar (MOBILE)
        headerMobileBackground={colorsTypes.WHITE} //  Color de fondo del navbar
        headerMobileHoverTransparentBackground={colorsTypes.TRANSPARENT} //  Color de fondo del boton de login en el navbar (solo funciona cuando la propiedad TRANSPARENT es TRUE) (solo funciona cuando se hizo SCROLL) en esu estado HOVER
        headerMobileBackgroundAfterBreakpoint={colorsTypes.WHITE} // Color de fondo del menu de navegación después de hacer SCROLL
        headerMobileBackgroundAfterBreakpointHover={colorsTypes.WHITE} // Color de fondo del menu de navegación después de hacer SCROLL
        headerMobileTransparentBackground={colorsTypes.TRANSPARENT} //  Color de fondo del navbar (solo funciona cuando la propiedad TRANSPARENT es TRUE)
        headerTransparentBackground={colorsTypes.TRANSPARENT} //  Color de fondo del menu de navegación en su estado Transparente
        headerHoverTransparentBackground={colorsTypes.TRANSPARENT} // Color de fondo del menu de navegación en su estado
        headerMobileLogoReplaceImageWithAfterBreakpoint={`url("/images/logo-black.svg")`}
        headerMobileMenuIconStyles={{
          mobileFilterEffectAfterScroll: ["none"], //  Filtro personalizado para el icono en MOBILE despues de hacer SCROLL
        }}
        // Forzar el uso de un Template
        // forceCustomVariant
        // variant={homeTemplatesVersionVariantTypes.HOME_TEMPLATE_V5}
        // Personalizar Hero
        heroProps={heroProps}
        // Personalizar Lista de Productos e items
        productsProps={productsProps}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
        // Personalizar Tabs
        tabsProps={tabsProps}
      />

      <AboutUsPage
        {...commonProps}
        path="/about-us"
        variant={aboutUsTemplatesVersionVariantsTypes.ABOUT_US_TEMPLATE_V2}
        // Personalizar Page Header
        pageHeaderProps={{}}
        // Personalizar Body Text
        bodyTextProps={{}}
        // Personalizar Callout Text
        calloutTextProps={{}}
        // Personalizar Callout Button
        calloutButtonProps={{}}
        // Personalizar Tabs
        tabsProps={tabsProps}
      />

      <ContactUsPage
        {...commonProps}
        path="/contact-us"
        variant={contactUsTemplatesVersionVariantsTypes.CONTACT_US_TEMPLATE_V3}
        submitButton={{}}
        isDinamic="true"
        // Personalizar Header
        pageHeaderProps={{}}
        // Personalizar Page Title
        pageTitleProps={{}}
        // Personalizar Contact
        contactProps={{}}
        // Personalizar Branch
        branchProps={{}}
        // Personalizar  Callout
        calloutProps={{}}
        // Personalizar  Callout Mobile With Gradient
        calloutMobileWithGradientPros={{}}
        // Personalizar Tabs
        tabsProps={tabsProps}
      />

      <DynamicPage
        {...commonProps}
        path="/:id"
        variant={dynamicTemplatesVersionVariantsTypes.DYNAMIC_TEMPLATE_V3}
        // Personalizar Hero
        heroProps={{}}
        // Personalizar Lista de Productos
        productsProps={{}}
        // Personalizar Feature Full
        featureFullProps={{}}
        // Personalizar Feature
        featureProps={{}}
        // Personalizar Callout
        calloutProps={{}}
        // Personalizar Callout Mobile
        calloutMobileProps={{}}
        // Personalizar Callout Compressed
        calloutCompressedProps={{}}
        // Personalizar Tabs
        tabsProps={tabsProps}
        // Personalizar Custom Page
        customPageProps={{
          // Personalizar de Hero's
          customComponentStyles: {
            // Personalizar de Hero's de tipo Dynamic
            hero: {
              dinamic: {
                // Titulo
                title: {
                  fontSize: "62px", // Tamaño del titulo
                },
                // Sub titulo
                subTitle: {
                  fontSize: "26px", // Tamaño de los sub titulo
                  fontFamily: "var(--secondary-family)", // Tipo de fuente de los sub titulo
                },
                img: {},
              },
            },
          },
        }}
      />
    </Router>
  );
}
